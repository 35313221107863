import React, { useState, useEffect } from "react";
import ModalPopup from "../Common/ModalPopup";
import styles from "./DivisionGroup.module.css";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  getProduct,
  getTerritoryForHOUser,
} from "../../Redux/actions/utility-actions";
import { getSalesCodeApi } from "../../services/DivisonGroup/getSalesCodeApi";
import { getSH7CodeHoUserApi } from "../../services/hoUser/getSH7CodeHoUserApi";
import { getClusterCode } from "../../Redux/actions/zsm-action";
import {
  getSecondarySalesAcheived,
  getSecondarySalesAcheivedTrend,
  getSecondarySalesTargetAcheived,
  getSecondarySalesTargetAcheivedTrend,
} from "../../Redux/actions/secondarySales-actions";
import {
  getTeamsAverageTsiScore,
  getTeamsAverageTsiScoreTrend,
  getTeamsAverageTsrScore,
  getTeamsAverageTsrScoreTrend,
  getTeamsIncentive,
  getTeamsIncentiveTrend,
  getTeamsSop,
  getTeamsSopTrend,
} from "../../Redux/actions/teams-action";
import {
  getWssAutoVAlueFtd,
  getWssAutoValueFtdTrend,
  getWssCodeForHORDM,
  getTownsForHORDM,
  getWssDarpanSyncDaysException,
  getWssWssInBlock,
} from "../../Redux/actions/wss-actions";
import {
  getOutletsBilledFtm,
  getOutletsBilledFtmTrend,
  getRsmFtm,
  getRsmFtmTrend,
} from "../../Redux/actions/salesOperation-action";
import { getHoNsmEmail } from "../../Redux/actions/hoUser-action";
import { getUniqueListBy } from "../Common/Function";
import Loader from "../Common/Loader";
import { SaveSelectedSalesGroupCode } from "../../Redux/actions/saveSelectedSalesGroup-action";

const DivisionGroup = ({
  getClusterCode,
  getTerritoryForHOUser,
  setIsDivisionGroupClicked,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [groupData, setGroupData] = useState({
    division: "",
    sales: "",
  });
  const [formValidation, setFormValidation] = useState({});
  const [selectedDivisionCode, setSelectedDivisionCode] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [salesCode, setSalesCode] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const divisionCode = useSelector((state) =>
    getUniqueListBy(state?.divisionGroup?.getDivisionGroup, "division")
  );

  const userName = useSelector((state) => state?.userDetails?.details?.Name);

  const userDivision = useSelector(
    (state) => state?.userDetails?.details?.Division
  );

  const onChange = (e) => {
    setGroupData({ ...groupData, [e.target.name]: e.target.value });
    if (e.target?.name === "division") {
      setSelectedDivisionCode(e.target?.value);
    }
  };

  useEffect(() => {

  })

  // api call to get the sales code
  useEffect(() => {
    if (!_.isEmpty(selectedDivisionCode) || userDivision == 70 || userDivision == 30) {
      (async () => {
        console.log("selectedDivisionCode", userDivision)
        const divisionCode = userDivision == 70 || userDivision == 30 ? parseInt(userDivision, 10) : selectedDivisionCode;
        console.log("divisionCode", divisionCode)
        const response = await getSalesCodeApi(divisionCode);
        if (response?.status === 200) {
          setSalesCode(response?.data);
        } else {
          console.log("errr");
          // setError(response?.msg);
        }
      })();
    }
  }, [selectedDivisionCode, userDivision]);

  const handleClose = () => setIsModalOpen(false);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setLoading(true);
    setFormValidation(checkValidation(groupData));
  };

  //validation check
  const checkValidation = (values) => {
    const errors = {};
    console.log("value", userDivision)
    if (!values.division && userDivision != 70 && userDivision != 30) {
      console.log("If Division", !values.division, userDivision)
      errors.division = "Division group is required";
    }
    if (!values.sales) {
      console.log("else sales", values.sales)
      errors.sales = "Sales group is required";
    }
    return errors;
  };

  //post api call of get territory
  useEffect(() => {
    console.log("submit", Object?.keys(formValidation)?.length, userDivision, groupData?.division )
    if (
      Object?.keys(formValidation)?.length === 0 &&
      isSubmit &&
      (groupData?.division || userDivision == 70 || userDivision == 30)
    ) {
      (async () => {
        console.log("userDivision", userDivision == 70 || userDivision == 30 ? userDivision : groupData.division)
        const data = {
          divisionGroup: userDivision == 70 || userDivision == 30 ? userDivision : groupData.division,
          salesGroup: groupData.sales,
        };

        const { divisionGroup, salesGroup } = data;
        dispatch(SaveSelectedSalesGroupCode(salesGroup))
        const res = await getSH7CodeHoUserApi(divisionGroup, salesGroup);
        const clusterRes = await getClusterCode(
          res?.data?.data?.[0]?.sh7code,
          "HO Collaborator",
          divisionGroup,
          salesGroup
        );
        dispatch(getWssCodeForHORDM(clusterRes));
        //dispatch(getTownsForHORDM(clusterRes));
        dispatch(getProduct(divisionGroup, salesGroup));
        dispatch(getHoNsmEmail(salesGroup));
        getDashboardQueries(res?.data?.data?.[0]?.sh7code, "HO Collaborator");
        await getTerritoryForHOUser({
          divisionGroup,
          salesGroup,
        });
        setLoading(false);
        setIsModalOpen(false);
        setGroupData({
          division: "",
          sales: "",
        });
      })();

      let pop_status = localStorage.getItem("pop_status");
      if (pop_status > 1) {
        setIsDivisionGroupClicked(false);
      }
    }
    if (Object?.keys(formValidation)?.length !== 0) {
      setIsSubmit(false);
    }
  }, [isSubmit]);

  // to get Dashboard queries
  const getDashboardQueries = (SH_Code__c, Title) => {
    dispatch(getSecondarySalesAcheived(SH_Code__c, Title));
    dispatch(getSecondarySalesAcheivedTrend(SH_Code__c, Title));
    dispatch(getSecondarySalesTargetAcheived(SH_Code__c, Title));
    dispatch(getSecondarySalesTargetAcheivedTrend(SH_Code__c, Title));
    dispatch(getTeamsAverageTsiScore(SH_Code__c, Title));
    // dispatch(getTeamsAverageTsiScoreTrend(SH_Code__c, Title));
    dispatch(getTeamsAverageTsrScore(SH_Code__c, Title));
    // dispatch(getTeamsAverageTsrScoreTrend(SH_Code__c, Title));
    dispatch(getTeamsIncentive(SH_Code__c, Title));
    // dispatch(getTeamsIncentiveTrend(SH_Code__c, Title));
    dispatch(getTeamsSop(SH_Code__c, Title));
    // dispatch(getTeamsSopTrend(SH_Code__c, Title));
    dispatch(getWssAutoVAlueFtd(SH_Code__c, Title));
    dispatch(getWssAutoValueFtdTrend(SH_Code__c, Title));
    dispatch(getWssWssInBlock());
    dispatch(getWssDarpanSyncDaysException(SH_Code__c, Title));
    dispatch(getOutletsBilledFtm(SH_Code__c, Title));
    dispatch(getOutletsBilledFtmTrend(SH_Code__c, Title));
    dispatch(getRsmFtm(SH_Code__c, Title));
    dispatch(getRsmFtmTrend(SH_Code__c, Title));
  };

  return (
    <>
      <ModalPopup
        isModalOpen={isModalOpen}
        setIsModalOpen={handleClose}
        title={`Welcome,${userName}`}
        className="divisionGroup__OuterBox"
        backdrop={"static"}
        fade={false}
        subtitle={
          (userDivision == 70 || userDivision == 30)
            ? `Your Division is ${userDivision} please select the salesgroup code`
            : "Please select division code and salesgroup code"
        }
        data={
          <>
            <Loader loading={loading} />
            {console.log("test", userDivision, userDivision == 70 || userDivision == 30)}
            {(userDivision == 70 || userDivision == 30) ? (
              <></>
            ) : (
              <>
                <div className={styles.divisionGroup__form}>
                  <label>
                    Division Code<span style={{ color: "red" }}>*</span>
                  </label>
                  <br />
                  <select
                    className={styles.divisionGroup__input}
                    value={groupData?.division}
                    name="division"
                    onChange={(e) => onChange(e)}
                  >
                    <option
                      value=""
                      selected={_.isEmpty(groupData?.division) ? true : false}
                      className={styles.divisionGroup__defaultOption}
                    >
                      Division Group
                    </option>
                    {divisionCode?.length > 0 ? (
                      divisionCode?.map((type) => {
                        return (
                          <>
                            <option value={type?.division}>
                              {type?.division_name}:{type?.division}
                            </option>
                          </>
                        );
                      })
                    ) : (
                      <option>Loading...</option>
                    )}
                  </select>
                  {!groupData?.division && formValidation?.division && (
                    <p className={styles.divisionGroup__validation}>
                      {formValidation?.division}
                    </p>
                  )}
                </div>
              </>
            )}

            <div className={styles.divisionGroup__form}>
              <label style={{ whiteSpace: "nowrap" }}>
                Sales Group<span style={{ color: "red" }}>*</span>
              </label>
              <br />
              <select
                className={styles.divisionGroup__input}
                value={groupData?.sales}
                name="sales"
                onChange={(e) => onChange(e)}
              >
                <option
                  value=""
                  selected={_.isEmpty(groupData?.sales) ? true : false}
                  className={styles.divisionGroup__defaultOption}
                >
                  Sales Group
                </option>
                {salesCode?.length > 0 ? (
                  salesCode?.map((code) => {
                    return (
                      <>
                        <option value={code?.sales_group_code_c}>
                          {code?.sales_group_code_c}
                          {code?.sales_group_name &&
                            ` : ${code?.sales_group_name}`}
                        </option>
                      </>
                    );
                  })
                ) : (
                  <option>Loading...</option>
                )}
              </select>
              {!groupData?.sales && formValidation?.sales && (
                <p className={styles.divisionGroup__validation}>
                  {formValidation?.sales}
                </p>
              )}
            </div>

            <div className={styles.divisionGroup__submitBtnBox}>
              <Button
                className={styles.divisionGroup__cancel}
                variant="secondary"
                type="submit"
              >
                <p
                  className={styles.divisionGroup__cancelBtn}
                  onClick={onSubmit}
                >
                  Submit
                </p>
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};

export default connect(null, {
  getClusterCode,
  getTerritoryForHOUser,
})(DivisionGroup);
