import React, { useState, useEffect, useRef } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./powerBi.css";
import { getPbiAccessToken } from "../Redux/actions/utility-actions";
import _ from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import { initializeEventListeners } from "../Components/Common/Function";


const PowerBi = ({ getPbiAccessToken, pbiAccessToken, pageName, id, dealerCode ,wssCode }) => {
  const [view, setView] = useState();
  const [columnName, setColumnName] = useState("SH3Email");
  const userEmail = useSelector((state) => state?.userDetails?.details?.Email);
  const nsmEmail = useSelector((state) => state?.HoUserSh7Code?.nsmEmail);
  const title = useSelector((state) => state?.userDetails?.details?.Title);
  const [pbiFilter, setPbiFilter] = useState([]);
  const dispatch = useDispatch();
  const expiryTime = useSelector((state) => state?.tokens?.creationDate);
  const eventCountRef = useRef(0);
  const hasEventListeners = useRef(false);

  useEffect(() => {
    const cleanup = initializeEventListeners(expiryTime, dispatch, eventCountRef, hasEventListeners);
    return cleanup;
  }, [expiryTime, dispatch]);

  useEffect(() => {
    if (title === "SH7") {
      setColumnName("SH7Email");
    }
    if (title === "SH6" || title === "SH5") {
      setColumnName("SH5Email");
    }
    if (title === "HO Collaborator") {
      setColumnName("SH7Email");
    }
  }, []);

  useEffect(() => {
    if (window.matchMedia("(max-width: 600px)").matches) {
      setView(models.LayoutType.MobilePortrait);
    } else {
      setView(models.LayoutType.MobileLandscape);
    }
    getPbiAccessToken();
  }, []);

  // PBI filter
  useEffect(() => {
    if (!_.isEmpty(wssCode)){
    var wssFilter =  [{
        $schema: "http://powerbi.com/product/schema#basic",
          target: {
          table: "DimCustomer",
            column: "Customer Code",
                  },
        operator: "EqualTo",
          values: [wssCode],
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "DimSalesHierarchy",
          column: columnName,
        },
        operator: "In",
        values: title === "HO Collaborator" ? [nsmEmail] : [userEmail],
      }]
      setPbiFilter(wssFilter)
    } else if(!_.isEmpty(dealerCode)) {
      var dealerfilter = [{
        $schema: "http://powerbi.com/product/schema#basic",
          target: {
          table: "DimDealer_MS",
            column: "Dealer Code",
                  },
        operator: "EqualTo",
          values: [dealerCode],
      },
      {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "DimSalesHierarchy",
          column: columnName,
        },
        operator: "In",
        values: title === "HO Collaborator" ? [nsmEmail] : [userEmail],
      }]
      setPbiFilter(dealerfilter)
    } else{
      setPbiFilter([{
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
          table: "DimSalesHierarchy",
          column: columnName,
        },
        operator: "In",
        values: title === "HO Collaborator" ? [nsmEmail] : [userEmail],
      }])
    }

  },[wssCode,dealerCode])

  return (
    <>
      <div className="Pbi__Box">
        {/* {console.log("isempty", !_.isEmpty(view), _.isUndefined(view))} */}
        {pbiAccessToken && view && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, and qna.
              id: id,
              embedUrl: "https://app.powerbi.com/reportEmbed/",
              pageName: pageName,
              accessToken: pbiAccessToken,
              tokenType: models.TokenType.Aad, // Use models.TokenType.Aad if you're embedding for your organization.

              // filtrt apply for bm zsm and nsm
              filters: pbiFilter,
              settings: {
                layoutType: view,

                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function () {
                    console.log("Report loaded");
                  },
                ],
                [
                  "rendered",
                  function () {
                    console.log("Report rendered");
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event.detail);
                  },
                ],
              ])
            }
            cssClassName={"report-style-class"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pbiAccessToken: state?.pbiToken?.pbiAccessToken,
});

export default connect(mapStateToProps, { getPbiAccessToken })(PowerBi);
