import axios from "axios";

import { BASE_URL } from "../../../variables";

export const getWssApi = async ({ SH2_Code__c }) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q= SELECT Id,CustomerName__c,Customer_Code__c,TSI_Territory_Code__c,WSS_latitude__c,WSS_longitude__c,Monthly_Tour_Plan_CheckIn__c,Town__c,TownCode__c FROM Account WHERE  recordtype.Name = 'Customer' and TSI_Territory_Code__c='${SH2_Code__c}'`,
    method: "get",
  };
  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};